import React from "react";
import {Secondary} from "../components/layouts/Secondary/Secondary";
import styled from "styled-components";

const Wrapper = styled.section`
  padding: 100px 11.32%;
  main {
    .last-updated {
      font-size: 1.5rem;
      margin-bottom: 0;
      margin-left: 20px;
      @media (max-width: 599px) {
        line-height: 1.5;
      }
    }
    .all-figures {
      font-size: 1.5rem;
      margin-bottom: 30px;
      margin-left: 20px;
      @media (max-width: 599px) {
        font-size: 1.3rem !important;
        line-height: 1.5;
        margin-top: 20px;
      }
    }
    .mt-60 {
      margin-top: 60px;
    }
    table {
      border: 3px solid #7165d0;
      border-radius: 30px;
      border-spacing: 0;
      max-width: 950px;
      width: 100%;
      thead {
        tr {
          th {
            padding: 20px;
            font-size: 2rem;
            text-align: left;
            border-bottom: 3px solid #7165d0;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 20px;
            &:last-child {
              border-left: 3px solid #7165d0;
              font-size: 1.6rem;
              width: 130px;
              vertical-align: middle;
              @media (max-width: 768px) {
                vertical-align: top;
                width: auto;
                padding-top: 23px;
              }
            }
            h2 {
              font-size: 1.6rem;
              @media (max-width: 768px) {
                line-height: normal;
                text-align: left;
                margin-bottom: 10px;
                font-size: 1.3rem;
              }
              &.with-dot::before {
                content: "";
                background: #7165d0;
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-right: 20px;
              }
            }
            p {
              text-align: left;
              @media (max-width: 768px) {
                line-height: 1.4;
              }
            }
          }
        }
      }
    }
    li {
      list-style: auto;
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 34px;
    }
    .list-style-none li {
      list-style: none;
    }
    .padding-none {
      padding: 0;
    }
    .margin-none {
      margin: 0;
    }
    .ul-li-margin-none {
      li {
        margin: 0;
      }
    }
    .line-height-normal {
      line-height: normal;
    }
    ol.list-style-lower-roman li {
      list-style: lower-roman;
    }
    .ol-left-padding {
      padding-left: 90px;
    }
    .underline-text {
      text-decoration: underline;
    }
  }
`;
const H4 = styled.h4`
  font-size: 22px;
`;
const WaitListSection = styled.section`
  max-width: 100%;
  padding-top: 128px;

  h2 {
    max-width: 885px;
    font-size: 64px;
    margin: 0 auto;
    padding-bottom: 40px;
    line-height: 75px;
    text-align: center;
    @media (max-width: 599px) {
      font-size: 40px;
      line-height: 47px;
      padding: 0 10px 40px 10px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 40px;
      line-height: 47px;
      padding: 0 100px;
      margin-bottom: 30px;
    }
  }
`;
const Waitlist = styled.div`
  padding: 0 20px;
  padding-bottom: 128px;
  display: grid;
  grid-template-columns: 383px 183px;
  gap: 20px;
  justify-content: center;
  @media (max-width: 599px) {
    grid-template-columns: 1fr;
  }
`;

const TermsAndConditions = () => (
  <Secondary>
    <Wrapper>
      <main>
        <h2 className="last-updated">Last updated: December 6, 2022</h2>
        <h2 className="all-figures">
          All figures in Canadian dollars, unless otherwise specified.
        </h2>
        <table>
          <thead>
            <tr>
              <th colSpan={2}>Trading</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h2>
                  Buying and selling US listed securities (including ETFs)
                </h2>
                <p>
                  Flahmingo offers commission-free investing meaning we don't
                  charge any com missions when you buy and sell securities.
                </p>
              </td>
              <td>$0</td>
            </tr>
            <tr>
              <td>
                <h2>Regulatory trading fees</h2>
                <p>
                  The U.S. Securities and Exchange Commission (SEC) and the
                  Financial Industry Regulator Authority (FINRA) charge a fee
                  for selling stock. Flahmingo pays these fees, and does not
                  pass them on to you.
                </p>
              </td>
              <td>$0</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-60">
          <thead>
            <tr>
              <th colSpan={2}>Funding</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h2>
                  Bank transfers via Interac e-Transfers for deposits, and
                  electronic funds transfers (EFTs) for withdrawals
                </h2>
              </td>
              <td>$0</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-60">
          <thead>
            <tr>
              <th colSpan={2}>Services</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h2 className="with-dot">
                  Voluntary corporate actions/election (each; in USD)
                </h2>
              </td>
              <td>$100</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">
                  Electronic statements and trade confirmations
                </h2>
              </td>
              <td>$0</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">Paper monthly statements (each)</h2>
              </td>
              <td>$150</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">Paper annual statements (each)</h2>
              </td>
              <td>$150</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">Paper trade confirmations (each)</h2>
              </td>
              <td>$150</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">
                  Other requests and investigations (per hour)
                </h2>
              </td>
              <td>$100</td>
            </tr>
          </tbody>
        </table>

        <table className="mt-60">
          <thead>
            <tr>
              <th colSpan={2}>Account</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h2 className="with-dot">Account opening fees</h2>
              </td>
              <td>$0</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">Account closing fees</h2>
              </td>
              <td>$0</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">Inactive account fees</h2>
              </td>
              <td>$0</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">Account maintenance fees</h2>
              </td>
              <td>$0</td>
            </tr>
            <tr>
              <td>
                <h2 className="with-dot">Currency conversion fees </h2>
                <p>
                  Flahmingo only charges currency conversion fees when you
                  convert from CAD to USD and vice versa. Once your funds are in
                  USD, you can buy and sell securities with no currency
                  conversion fees. This fee is charged on the foreign exchange
                  rate provided by our liquidity partner
                </p>
              </td>
              <td>2.50%</td>
            </tr>
          </tbody>
        </table>
      </main>
    </Wrapper>
  </Secondary>
);
export default TermsAndConditions;