import React from "react";
import {Helmet} from "react-helmet";
import {Header} from "../../header/header";
import {useLocation} from "@reach/router";
import "./styles.scss";
import {Footer} from "../../Footer/Footer";
import {Link as LinkScroll} from "react-scroll";
import styled from "styled-components";

export const Secondary = (props: any) => {
  const location = useLocation();
  const headerTitle = () => {
    switch (location.pathname) {
      case "/legal/terms-and-conditions/":
        return "Terms and Conditions";
      case "/legal/privacy-policy/":
        return "Privacy Policy";
      case "/legal/complaint-handling-process/":
        return "Complaint Handling Process";
      case "/legal/conflict-of-interest-statement/":
        return "Conflict of Interest Statement";
      case "/legal/best-execution-and-fair-pricing-disclosure/":
        return "Best Execution and Fair Pricing Disclosure";
      case "/legal/account-relationship-disclosure/":
        return "Account Relationship Disclosure";
      case "/fees-schedule/":
        return " Fee Schedule ";
      case "/promo/sign-up-promo/":
        return "$10 Promotion Terms & Conditions";
      default:
        break;
    }
  };
  return (
    <>
      <Helmet>
        <title>Flahmingo</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1, viewport-fit=cover"
        />
      </Helmet>
      <div className="header-wrapper">
        <Header secondary />
        <div className="header-title">
          <h1>{headerTitle()}</h1>
        </div>
        {location.pathname === "/press" && (
          <Submenu>
            <ul>
              <li>
                <LinkScroll to="inTheNews" spy={true} smooth={true}>
                  In the News
                </LinkScroll>
              </li>
              {/* <li>
                <LinkScroll to="aboutUs" spy={true} smooth={true}>
                  About us
                </LinkScroll>
              </li> */}
              <li>
                <LinkScroll to="pressKit" spy={true} smooth={true}>
                  Press kit & Resources
                </LinkScroll>
              </li>
              {/* <li>
                <LinkScroll to="brandGuidelines" spy={true} smooth={true}>
                Brand guidelines
                </LinkScroll>
              </li> */}
              <li>
                <LinkScroll to="imageGallery" spy={true} smooth={true}>
                  Image gallery
                </LinkScroll>
              </li>
            </ul>
          </Submenu>
        )}
      </div>
      <div className="content-wrapper">{props.children}</div>
      <Footer />
    </>
  );
};

const Submenu = styled.section`
  padding: 20px 20rem;
  background-color: #f1f2f2;
  @media (max-width: 1200px) {
    padding: 2rem 0;
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    @media (max-width: 1200px) {
      display: block;
      text-align: center;
    }
    li {
      padding: 10px 0;
      cursor: pointer;
    }
  }
`;